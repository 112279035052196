import { mapZones } from '@/types/common/MapZones';
import { ILandPlot, PlotStatus } from '@/types/LandPLot';

export const landPlotPlaceholder: Readonly<ILandPlot> = {
    id: 0,
    house: {
        id: 0,
        name: '',
        descriptionIn: '',
        descriptionOut: '',
        area: 0,
        floors: 0,
        images: ['', ''],
        in: [],
        out: [],
    },
    plot: {
        id: 0,
        name: '',
        area: 0,
        houseNumber: '',
        street: { id: '', name: '' },
        status: PlotStatus.free,
        number: 0,
        cost: 0,
        newCost: 0,
        description: '',
        facilitiesIdList: [],
        images: [],
        parameters: [],
        zone: mapZones.empty,
        action: null,
        additional: null,
        cadastralNumber: '',
        discount: { value: null, description: null },
    },
};
