






















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import CardTemplate from '@/components/layouts/CardTempate.vue';
import { IGlobalMapZoneHoverInfo } from '@/types/common/MapZones';

@Component({
    components: { CardTemplate },
})
export default class GeneralMapHoverPopup extends Vue {
    @Prop() zoneInfo!: IGlobalMapZoneHoverInfo;
    @Prop() targetDomRect!: DOMRect;
    @Prop({ default: false }) isRelaxationZone!: boolean;

    widthCard: any = 0;

    get urlBackgroundImage() {
        return this.zoneInfo.areHousesExist
            ? require('@/assets/img/Map/houses.jpg')
            : require('@/assets/img/Map/plots.jpg');
    }

    get objectStyle() {
        const targetCenter = {
            x:
                window.scrollX +
                this.targetDomRect.x +
                this.targetDomRect.width / 2,
            y:
                window.scrollY +
                this.targetDomRect.y +
                this.targetDomRect.height / 2,
        };

        return {
            opacity: this.zoneInfo.title ? '1' : '0',
            left: `${targetCenter.x}px`,
            top: `${targetCenter.y}px`,
        };
    }

    mounted() {
        setTimeout(() => {
            if (
                this.$refs.houseCard &&
                this.$refs.houseCard instanceof Element
            )
                this.widthCard = this.$refs.houseCard.clientWidth;
        });
    }
}
