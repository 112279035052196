











































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import CustomButton from '@/components/commons/CustomControls/CustomButton.vue';
import CustomList from '@/components/commons/CustomList.vue';
import HouseIcon from '@/components/commons/icons/HouseIcon.vue';

const SliderGallery = () => import('@/components/slider/SliderGallery.vue');
const SliderGalleryMobile = () =>
    import('@/components/slider/SliderGalleryMobile.vue');

import { ILandPlot } from '@/types/LandPLot';
import { CustomListItem } from '@/types/common/CustomList';

import { cashSpacer } from '@/helpers/cashHelpers';

@Component({
    components: {
        CustomButton,
        CustomList,
        SliderGallery,
        SliderGalleryMobile,
        HouseIcon,
    },
})
export default class LandPlotModalTop extends Vue {
    @Prop({
        default: () => ({
            id: 1,
            house: {
                id: -1,
                name: '',
                descriptionIn: '',
                descriptionOut: '',
                area: 0,
                floors: 0,
                images: [],
                in: [
                    {
                        id: -1,
                        name: '',
                        value: '',
                    },
                ],
                out: [
                    {
                        id: 1,
                        name: '',
                        value: '',
                    },
                ],
            },
            plot: {
                id: -1,
                area: 0,
                status: 'free',
                number: 0,
                cost: 0,
                description: '',
                facilitiesIdList: {},
                images: [],
                parameters: [],
            },
        }),
    })
    landPlot!: ILandPlot;

    get formatedCost() {
        return cashSpacer(this.landPlot.plot.cost, ' ');
    }

    get formatedNewCost() {
        return cashSpacer(this.landPlot.plot.newCost, ' ');
    }

    get cadastralNumber() {
        return this.landPlot.plot.cadastralNumber;
    }

    get listTop(): Array<CustomListItem> {
        const result = [
            {
                id: -1,
                title: 'Площадь участка,  соток',
                text: this.landPlot.plot.area
                    ? this.landPlot.plot.area.toString()
                    : '',
            },
        ];

        if (this.landPlot.house)
            this.landPlot.house.out.forEach(i =>
                result.push({
                    id: i.id,
                    title: i.name,
                    text: i.value,
                }),
            );

        return result;
    }

    showModalFromTilda() {
        const btn: HTMLElement | null = document.querySelector(
            '.show-modal-promotion',
        );
        if (!btn) return;
        btn.click();
        this.$emit('close');
    }
    openTildaModalSignUpForViewing() {
        const btn: HTMLElement | null = document.querySelector(
            'a[href="#popup:viewing"]',
        );

        if (!btn)
            return console.error(
                'Не удалось найти кнопку a[href="#popup:viewing"]',
            );

        btn.click();
        const id = this.landPlot.plot.number
            ? this.landPlot.plot.number
            : null;
        if (!id) {
            console.error(
                'ID участка не был найден, проверьте верность заполнения участка в админке',
            );
        }
        const input: HTMLInputElement | null = document.querySelector(
            '.sign-up-for-viewing__plot-filed input[type="hidden"]',
        );

        if (!input) {
            console.error('Не удалось найти инпут');
            // return;
        } else {
            input.value = String(id);
        }
        this.$emit('close');
    }

    openTildaModalAskQuestion() {
        const btn: HTMLElement | null = document.querySelector(
            'a[href="#popup:consultation"]',
        );
        if (!btn) {
            console.error(
                'Не удалось найти кнопку a[href="#popup:consultation"]',
            );
            return;
        }
        btn.click();
        const id = this.landPlot.plot.number
            ? this.landPlot.plot.number
            : null;
        if (!id) {
            console.error(
                'ID участка не был найден, проверьте верность заполнения участка в админке',
            );
        }
        const input: HTMLInputElement | null = document.querySelector(
            '.ask-a-question__field input[type="hidden"]',
        );

        if (!input) {
            console.error('Не удалось найти инпут');
            // return;
        } else {
            input.value = String(id);
        }
        this.$emit('close');
    }

    openInFullScreen(data: number) {
        this.$emit('openInFullScreenPlotModalGallery', data);
    }

    openCadastralPage() {
        if (!this.cadastralNumber) return;
        const text = this.cadastralNumber.replace(/:/g, '%3A');
        const opened = this.cadastralNumber
            .split(':')
            .map(item => {
                if (item.startsWith('0')) item = item.substring(1);
                return item;
            })
            .join('%3A');
        const url = `https://pkk.rosreestr.ru/#/search/?text=${text}&type=1&opened=${opened}&inPoint=true`;

        window.open(url, '_blank');
    }
}
