// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/svg/scroll-btn-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".map-footer{position:absolute;width:auto;top:30px;left:48px;transform:translateY(-100vh);box-sizing:border-box}@media (max-width:1600px){.map-footer{top:0}}@media (max-width:1280px){.map-footer{bottom:15px;top:unset;left:unset;width:100%;padding:0 24px}}@media (max-width:948px){.map-footer{left:0}}@media (max-width:575px){.map-footer{left:50%;transform:translateX(-50%) translateY(-100vh);bottom:17px}}.map-footer *{box-sizing:border-box}.map-footer__inner{max-width:1185px;margin:0 auto}@media (max-width:948px){.map-footer__inner{display:flex;justify-content:space-between;align-items:center;width:100%}}@media (max-width:575px){.map-footer__inner{flex-direction:column}}.status-list{background-color:hsla(0,0%,100%,.8);border-radius:0;padding:11px 15px 14px 16px;display:inline-flex;flex-direction:column;width:auto;margin:0;transform:translateY(100vh)}@media (max-width:1080px){.status-list{flex-direction:row;padding:8px 16px}}@media (max-width:575px){.status-list{order:1;margin-top:16px}}.status-list__item{display:inline-flex;align-items:center;margin-bottom:7px}.status-list__item:last-child{margin-bottom:0}@media (max-width:1080px){.status-list__item{margin-bottom:0;margin-right:16px}.status-list__item:last-child{margin-right:0}}.status-list__item-circle{width:6px;height:6px;border-radius:50%;margin-right:8px}.scroll-map{display:flex;width:64px;height:32px;background-color:transparent;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:50%;background-repeat:no-repeat;cursor:pointer;transform:translateY(100vh)}@media (min-width:949px){.scroll-map{display:none}}@media (max-width:575px){.scroll-map{order:0}}.scroll-map__btn{width:50%;height:100%;background-color:transparent;display:block;outline:unset;border:unset}", ""]);
// Exports
module.exports = exports;
