



















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CustomFilterButton from '@/components/commons/CustomControls/CustomFilterButton.vue';
import CustomListButton from '@/components/commons/CustomControls/CustomListButton.vue';
import CustomButtonBack from '@/components/commons/CustomControls/CustomButtonBack.vue';
import DownloadIcon from '@/components/commons/icons/DownloadIcon.vue';
@Component({
    components: {
        CustomFilterButton,
        CustomListButton,
        CustomButtonBack,
        DownloadIcon,
    },
})
export default class GeneralMapHeader extends Vue {
    @Prop({ default: false }) changeStatusFilter!: boolean;
    @Prop({ default: false }) isMasterPlan!: boolean;
    @Prop({ default: false }) isActiveFilter!: boolean;

    filterActive = false;

    tabs = [
        {
            id: 1,
            active: true,
            option: 'Показать на генплане',
            value: true,
        },
        {
            id: 2,
            active: false,
            option: 'Показать на карте',
            value: false,
        },
    ];
    tabsIsOpen = false;

    get currentTab() {
        return this.tabs.find(item => item.active === true);
    }

    setActive(index: number) {
        this.tabs = this.tabs.map(item => {
            item.active = false;
            return item;
        });
        this.tabs[index].active = true;
        this.tabsIsOpen = false;
        this.$emit('switchToMasterPlan', this.tabs[index].value);
    }

    onUploadSchemeImage() {
        const urlFromOtherDomain = `http${
            process.env.VUE_APP_COMPONENT_SECURE === 'true' ? 's' : ''
        }://${process.env.VUE_APP_COMPONENT_DOMAIN}/uploads/scheme_2D.jpg`;

        fetch(urlFromOtherDomain)
            .then(res => res.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');

                document.body.appendChild(link);
                link.setAttribute('download', 'scheme_2D');
                link.href = url;
                link.click();
                document.body.removeChild(link);
            });
    }

    @Watch('changeStatusFilter')
    onUpdate() {
        setTimeout(() => {
            this.filterActive = this.changeStatusFilter;
        });
    }
}
