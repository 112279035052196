var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"general-map-header"},[_c('div',{staticClass:"general-map-header__inner"},[_c('div',{staticClass:"general-map-header__tabs-wrapper"},[_c('div',{staticClass:"general-map-header__tabs"},_vm._l((_vm.tabs),function(item,i){return _c('button',{key:item.id,staticClass:"general-map-header__tabs-button text-xsm ff-opensans",class:{
                        'general-map-header__tabs-button--active':
                            item.active,
                    },on:{"click":function($event){return _vm.setActive(i)}}},[_vm._v(" "+_vm._s(item.option)+" ")])}),0),_c('div',{staticClass:"general-map-header__tabs-mobile ff-opensans",class:{
                    'general-map-header__tabs-mobile--open-list':
                        _vm.tabsIsOpen,
                }},[_c('div',{staticClass:"general-map-header__tabs-mobile-selected text-sm text-simple-light",on:{"click":function($event){_vm.tabsIsOpen = !_vm.tabsIsOpen}}},[_vm._v(" "+_vm._s(_vm.currentTab.option)+" ")]),_c('div',{staticClass:"general-map-header__tabs-mobile-list",class:{
                        'general-map-header__tabs-mobile-list--hide':
                            !_vm.tabsIsOpen,
                    }},_vm._l((_vm.tabs),function(item,i){return _c('button',{key:item.id,staticClass:"general-map-header__tabs-mobile-option text-sm text-dark-coal",class:{
                            'general-map-header__tabs-mobile-option--active':
                                item.active,
                        },on:{"click":function($event){return _vm.setActive(i)}}},[_vm._v(" "+_vm._s(item.option)+" ")])}),0)]),_c('button',{staticClass:"general-map-header__button ff-opensans",on:{"click":function($event){return _vm.onUploadSchemeImage()}}},[_c('span',[_vm._v(" Скачать 2D схему ")]),_c('DownloadIcon',{staticClass:"download-icon"})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMasterPlan),expression:"isMasterPlan"}],staticClass:"general-map-header__actions"},[_c('CustomFilterButton',{staticClass:"general-map-header__actions-filter-button",attrs:{"filterIsOpen":_vm.changeStatusFilter,"filterStatus":_vm.isActiveFilter},on:{"click":function($event){return _vm.$emit('switchFilters')}}},[_vm._v(" Фильтр ")]),_c('CustomListButton',{staticClass:"general-map-header__actions-list-button",on:{"click":function($event){return _vm.$emit('switchList')}}},[_vm._v("Список")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }