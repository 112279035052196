






import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import styleAsString from 'raw-loader!./assets/styles/inital-styles/fonts/fonts.txt';
import { landPlotStore } from './store/modules/LandPLot';

// Карты
import MapsSwitcher from '@/components/maps/MapsSwitcher.vue';

@Component({
    components: {
        MapsSwitcher,
    },
})
export default class App extends Vue {
    get filteredLandPlotsList() {
        return landPlotStore.FILTERED_LAND_PLOTS;
    }

    // font imports
    beforeCreate() {
        const styleElement = document.createElement('style');
        styleElement.innerHTML = styleAsString
            .split('%URL%')
            .join(
                `http${
                    process.env.VUE_APP_COMPONENT_SECURE === 'true'
                        ? 's'
                        : ''
                }://${process.env.VUE_APP_COMPONENT_DOMAIN}`,
            );
        document.head.appendChild(styleElement);
    }

    async created() {
        await landPlotStore.LOAD_LAND_PLOTS();
    }

    mounted() {
        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }
    beforeDestroy() {
        window.removeEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }
}
