var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"range-select text-md"},[_c('span',{ref:"railNumFrom",staticClass:"range-select__text range-select__text--start text-md ff-opensans",attrs:{"title":_vm.formatedValues.min}},[_vm._v(_vm._s(_vm.formatedValues.min))]),_c('div',{ref:"rail",staticClass:"range-select__rail"},[_c('span',{ref:"minValueThumb",staticClass:"range-select__rail-thumb range-select__rail-thumb--from",style:({
                transform: ("translate(" + _vm.minThumbMovement + "px, -50%)"),
                width: _vm.thumbWidth,
                height: _vm.thumbHeight,
            })}),_c('span',{staticClass:"range-select__rail-line",style:({
                display: 'block',
                transform: ("translate(" + _vm.minThumbMovement + "px, -50%)"),
                width: ((_vm.maxThumbMovement - _vm.minThumbMovement) + "px"),
            })},[_c('span',{staticClass:"range-select__rail-line-color"})]),_c('span',{ref:"maxValueThumb",staticClass:"range-select__rail-thumb range-select__rail-thumb--to",style:({
                transform: ("translate(" + _vm.maxThumbMovement + "px, -50%)"),
                width: _vm.thumbWidth,
                height: _vm.thumbHeight,
            })})]),_c('span',{ref:"railNumTo",staticClass:"range-select__text range-select__text--end text-md ff-opensans",attrs:{"title":_vm.formatedValues.max}},[_vm._v(_vm._s(_vm.formatedValues.max))])])}
var staticRenderFns = []

export { render, staticRenderFns }