















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cashSpacer } from '@/helpers/cashHelpers';
import CardTemplate from '@/components/layouts/CardTempate.vue';
import { ILandPlot, PlotStatus } from '@/types/LandPLot';

import { landPlotPlaceholder } from '@/placeholders/landPlot';
import { domRectPlaceholder } from '@/placeholders/domRect';
@Component({
    components: { CardTemplate },
})
export default class LandPlotPopover extends Vue {
    @Prop({
        default: () => landPlotPlaceholder,
    })
    landPlot!: ILandPlot;
    @Prop({ default: false }) isVisible!: boolean;
    @Prop({ default: () => domRectPlaceholder }) targetDomRect!: DOMRect;

    objectStyleData: any = {};

    get price() {
        return cashSpacer(this.landPlot.plot.cost || 0, ' ');
    }

    get newPrice() {
        return cashSpacer(this.landPlot.plot.newCost || 0, ' ');
    }

    get deadline() {
        if (!this.landPlot.plot.parameters) return '';
        const result = this.landPlot.plot.parameters.find(
            param => param.name === 'Срок сдачи',
        );
        return result ? result.value : '';
    }

    get status() {
        if (this.landPlot.plot.status === PlotStatus.free)
            return 'Свободен';
        if (this.landPlot.plot.status === PlotStatus.booked)
            return 'Забронирован';
        if (this.landPlot.plot.status === PlotStatus.occupied)
            return 'Занят';
    }

    get positionPopover() {
        const rootDomRect = this.$root.$el.getBoundingClientRect();
        const resultPosition = {
            x: 0,
            y: 0,
        };
        const popoverHeight = this.$el
            ? this.$el.getBoundingClientRect().height || 0
            : 0;
        const popoverWidth = this.$el
            ? this.$el.getBoundingClientRect().width || 0
            : 0;

        if (this.targetDomRect.y + rootDomRect.y * -1 > popoverHeight) {
            // по У сверху
            resultPosition.y =
                this.targetDomRect.y + this.targetDomRect.height / 2;
        } else if (
            this.targetDomRect.y +
                rootDomRect.y * -1 +
                this.targetDomRect.height +
                popoverHeight <
            rootDomRect.height
        ) {
            // по У снизу
            resultPosition.y =
                this.targetDomRect.y +
                this.targetDomRect.height +
                popoverHeight;
        } else {
            // по У по нижней границе карты
            resultPosition.y = rootDomRect.height + rootDomRect.y;
        }
        if (
            this.targetDomRect.x +
                this.targetDomRect.width / 2 +
                popoverWidth / 2 <
                rootDomRect.width &&
            !(resultPosition.y === rootDomRect.height + rootDomRect.y)
        ) {
            // по Х по центру
            resultPosition.x =
                this.targetDomRect.x + this.targetDomRect.width / 2;
        } else if (this.targetDomRect.x > popoverWidth) {
            // по Х по слева
            resultPosition.x = this.targetDomRect.x - popoverWidth / 2;
        } else if (
            this.targetDomRect.x + this.targetDomRect.width + popoverWidth <
            rootDomRect.width
        ) {
            // по Х по справа
            resultPosition.x =
                this.targetDomRect.x +
                this.targetDomRect.width +
                popoverWidth / 2;
        }

        return resultPosition;
    }

    objectStyle() {
        //сделал функцией а не гетером, потому что нужно чтобы вызывался метод с микрозадержкой
        // в сеттаймауте
        // иначе с гетером не всегда срабатывает правильно расчет высоты поповера getBoundingClientRect.height
        setTimeout(() => {
            const targetPostion = this.positionPopover;

            this.objectStyleData = {
                left: `${targetPostion.x}px`,
                top: `${targetPostion.y}px`,
                transform: `translate(-50%, -100%)`,
                opacity: this.isVisible ? '1' : '0',
            };
        });
    }

    @Watch('targetDomRect')
    on() {
        this.objectStyle();
    }
}
