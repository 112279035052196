
















import { Component, Vue, Prop } from 'vue-property-decorator';
import FilterIcon from '@/components/commons/icons/FilterIcon.vue';
@Component({
    components: { FilterIcon },
})
export default class CustomFilterButton extends Vue {
    @Prop({ default: false }) filterStatus!: boolean;
    @Prop({ default: false }) filterIsOpen!: boolean;
}
