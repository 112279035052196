import {
    Module,
    VuexModule,
    Mutation,
    getModule,
} from 'vuex-module-decorators';

import store from '@/store';
import { IScrollData } from '@/types/CardSettings';

export interface IMapScrollStore {
    TRANSLATE_CENTER: number;
    TRANSLATE_X: number;
    RELOAD_TRANSLATE_X(): void;
    SET_TRANSLATE_X(data: number): void;
    SET_DATA(data: IScrollData): void;
}

@Module({
    dynamic: true,
    store,
    name: 'mapScrollStore',
})
class MapScroll extends VuexModule implements IMapScrollStore {
    //
    private scrollDataObject: IScrollData = {
        mapWrapper: null,
        mapWrapperItem: null,
        z: 0,
        vw: 0,
        Xmax: 0,
    };

    private translateX: number = 0;

    get TRANSLATE_CENTER(): number {
        return this.scrollDataObject.z && this.scrollDataObject.vw
            ? this.scrollDataObject.z / 2 - this.scrollDataObject.vw / 2
            : 0;
    }
    //
    get TRANSLATE_X(): number {
        return this.translateX;
    }

    //
    @Mutation
    RELOAD_TRANSLATE_X(): void {
        const center =
            this.scrollDataObject.z && this.scrollDataObject.vw
                ? this.scrollDataObject.z / 2 - this.scrollDataObject.vw / 2
                : 0;
        this.translateX = center;
    }

    @Mutation
    SET_TRANSLATE_X(data: number): void {
        const center =
            this.scrollDataObject.z && this.scrollDataObject.vw
                ? this.scrollDataObject.z / 2 - this.scrollDataObject.vw / 2
                : 0;

        if (this.translateX === 0) {
            if (center + data < this.scrollDataObject.Xmax)
                this.translateX = this.scrollDataObject.Xmax;
            else if (center + data > 0) this.translateX = -1;
            else this.translateX = center + data;
        } else if (this.translateX + data > 0) this.translateX = -1;
        else if (this.translateX + data < this.scrollDataObject.Xmax)
            this.translateX = this.scrollDataObject.Xmax;
        else this.translateX += data;
    }

    @Mutation
    SET_DATA(data: IScrollData): void {
        this.scrollDataObject = { ...this.scrollDataObject, ...data };
    }
}

export const mapScrollStore = getModule(MapScroll);
