import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule,
} from 'vuex-module-decorators';

import store from '@/store';

import { getRoute } from '@/helpers/routeHelpers';
import { removeDuplicates } from '@/helpers/removeDuplicates';
import {
    IIncomingLandPlot,
    ILandPlot,
    ILandPlotFilter,
} from '@/types/LandPLot';
import { indexOf } from 'lodash';
import { IFacility } from '@/types/Facility';

const landPlotDataHandler = async (
    data: IIncomingLandPlot[],
): Promise<{
    main: ILandPlot[];
    price: { min: number; max: number };
    plotArea: { min: number; max: number };
    houseArea: { min: number; max: number };
    floors: number[];
    facalities: IFacility[];
}> => {
    return new Promise((resolve, reject) => {
        let minPrice = Infinity;
        let maxPrice = -Infinity;
        //
        let minPlotArea = Infinity;
        let maxPlotArea = -Infinity;
        //
        let minHouseArea = Infinity;
        let maxHouseArea = -Infinity;

        const main = data.map((item: IIncomingLandPlot) => {
            minPrice = Math.min(minPrice, item.plot_info.cost);
            maxPrice = Math.max(maxPrice, item.plot_info.cost);
            //
            minPlotArea = Math.min(minPlotArea, item.plot_info.area);
            maxPlotArea = Math.max(maxPlotArea, item.plot_info.area);
            //
            if (item.house_info) {
                minHouseArea = Math.min(minHouseArea, item.house_info.area);
                maxHouseArea = Math.max(maxHouseArea, item.house_info.area);
            }

            return {
                id: item.id,
                house: item.house_info
                    ? {
                          id: item.house_info.id,
                          name: item.house_info.name,
                          descriptionIn: item.house_info.description_in,
                          descriptionOut: item.house_info.description_out,
                          area: item.house_info.area,
                          floors: item.house_info.floors,
                          images: item.house_info.images
                              .sort((a, b) => a.sort - b.sort)
                              .map(item => item.URL),
                          in: item.house_info.in,
                          out: item.house_info.out,
                      }
                    : null,
                plot: {
                    id: item.plot_info.id,
                    name: item.plot_info.name,
                    area: item.plot_info.area,
                    houseNumber: item.plot_info.house_number,
                    street: item.plot_info.street,
                    status: item.plot_info.status,
                    number: item.plot_info.number,
                    cost: item.plot_info.cost,
                    newCost: item.plot_info.new_cost,
                    description: item.plot_info.description,
                    facilitiesIdList: item.plot_info.facilities.map(
                        item => item.id,
                    ),
                    images: item.plot_info.images
                        .sort((a, b) => a.sort - b.sort)
                        .map(item => item.URL),
                    parameters: item.plot_info.parameters,
                    zone: item.plot_info.zone,
                    action: item.plot_info.action,
                    additional: item.plot_info.additional,
                    cadastralNumber: item.plot_info.cadastral_number,
                    discount: item.plot_info.discount,
                },
            };
        });

        //
        const florsSet = new Set();
        const facalitiesList: IFacility[] = [];
        //
        main.forEach(item => {
            if (!item.house) return false;
            florsSet.add(item.house.floors);
        });
        data.forEach(item => {
            item.plot_info.facilities.forEach(el => {
                facalitiesList.push(el);
            });
        });
        //
        //@ts-ignore
        const floors: number[] = Array.from(florsSet).sort(
            //@ts-ignore
            (a: number, b: number) => a - b,
        );
        //
        const facalities = removeDuplicates(facalitiesList);
        //
        resolve({
            price: {
                min: minPrice,
                max: maxPrice,
            },
            plotArea: {
                min: minPlotArea,
                max: maxPlotArea,
            },
            houseArea: {
                min: minHouseArea,
                max: maxHouseArea,
            },
            main: main,
            floors: floors,
            facalities: facalities,
        });
    });
};

const landPlotDataFilter = async (
    list: ILandPlot[],
    filter: ILandPlotFilter,
): Promise<ILandPlot[]> => {
    return new Promise(async (resolve, reject) => {
        const result = list.filter(
            item =>
                // Проврка на наличие / отсутсвие дома
                ((item.house && filter.withHouse && !filter.withoutHouse) ||
                    (!item.house && filter.withoutHouse && !filter.withHouse) ||
                    filter.withHouse === filter.withoutHouse) &&
                // Проверка статуса участка
                (filter.status.length === 0 ||
                    !!(indexOf(filter.status, item.plot.status) + 1)) &&
                // Проверка на соответсвие площади дома
                (!item.house ||
                    (item.house.area >= filter.houseAreaMin &&
                        item.house.area <= filter.houseAreaMax)) &&
                // Проверка на соответсвие площади участка
                item.plot.area >= filter.landPLotAreaMin &&
                item.plot.area <= filter.landPLotAreaMax &&
                // Проверка на соответсвие цены
                item.plot.cost >= filter.landPLotPriceMin &&
                item.plot.cost <= filter.landPLotPriceMax &&
                // Проверка на колчиество этажей
                (!item.house ||
                    filter.allowedFloors.length === 0 ||
                    !!(indexOf(filter.allowedFloors, item.house.floors) + 1)) &&
                // Проверка на удобства
                (() => {
                    if (
                        Object.keys(filter.facilities).length &&
                        !item.plot.facilitiesIdList.some(
                            facilitiesFilter =>
                                filter.facilities[facilitiesFilter],
                        )
                    )
                        return false;
                    return true;
                })(),
        );
        resolve(result);
    });
};

export interface ILandPLotStore {
    LAND_PLOTS: ILandPlot[];
    SET_LAND_PLOTS: (data: ILandPlot[]) => void;
    LOAD_LAND_PLOTS: () => Promise<void>;
}

@Module({
    dynamic: true,
    store,
    name: 'landPLotStore',
})
class LandPLot extends VuexModule implements ILandPLotStore {
    private landPLots: ILandPlot[] = [];
    private filteredLandPLots: ILandPlot[] = [];

    private priceGap = {
        min: 0,
        max: 9999999999999,
    };

    private plotAreaGap = {
        min: 0,
        max: 9999999999999,
    };

    private houseAreaGap = {
        min: 0,
        max: 9999999999999,
    };

    floors = [] as number[];
    facalities: IFacility[] = [];

    landPLotsFilters: ILandPlotFilter = {
        withHouse: false,
        withoutHouse: false,
        status: [],

        houseAreaMin: 0,
        houseAreaMax: 9999999999999,

        landPLotAreaMin: 0,
        landPLotAreaMax: 9999999999999,

        landPLotPriceMin: 0,
        landPLotPriceMax: 9999999999999,

        allowedFloors: [],
        facilities: {},
    };

    get LAND_PLOTS(): ILandPlot[] {
        return this.landPLots;
    }
    get FILTERED_LAND_PLOTS(): ILandPlot[] {
        return this.filteredLandPLots;
    }
    get PRICE_GAP() {
        return this.priceGap;
    }
    get HOUSE_AREA_GAP() {
        return this.houseAreaGap;
    }
    get PLOT_AREA_GAP() {
        return this.plotAreaGap;
    }
    get FLOORS() {
        return this.floors;
    }
    get FACALITIES() {
        return this.facalities;
    }
    get LAND_PLOT_FILTERS() {
        return this.landPLotsFilters;
    }

    @Mutation
    SET_GAPS(data: {
        price: { min: number; max: number };
        plotArea: { min: number; max: number };
        houseArea: { min: number; max: number };
    }) {
        this.priceGap = data.price;
        this.houseAreaGap = data.houseArea;
        this.plotAreaGap = data.plotArea;
    }

    @Mutation
    SET_GAPS_DATA_FILTER(data: {
        price: { min: number; max: number };
        plotArea: { min: number; max: number };
        houseArea: { min: number; max: number };
    }) {
        this.landPLotsFilters.landPLotPriceMax = data.price.max;
        this.landPLotsFilters.landPLotPriceMin = data.price.min;
        //
        this.landPLotsFilters.landPLotAreaMax = data.plotArea.max;
        this.landPLotsFilters.landPLotAreaMin = data.plotArea.min;
        //
        this.landPLotsFilters.houseAreaMax = data.houseArea.max;
        this.landPLotsFilters.houseAreaMin = data.houseArea.min;
    }

    @Mutation
    async RESET_LAND_PLOT_FILTERS() {
        this.landPLotsFilters = {
            withHouse: false,
            withoutHouse: false,
            status: [],

            houseAreaMin: this.houseAreaGap.min,
            houseAreaMax: this.houseAreaGap.max,

            landPLotAreaMin: this.plotAreaGap.min,
            landPLotAreaMax: this.plotAreaGap.max,

            landPLotPriceMin: this.priceGap.min,
            landPLotPriceMax: this.priceGap.max,

            allowedFloors: [],
            facilities: {},
        };

        this.filteredLandPLots = this.landPLots;
    }

    @Mutation
    SET_LAND_PLOT_FILTERS(data: Partial<ILandPlotFilter>) {
        this.landPLotsFilters = {
            ...this.landPLotsFilters,
            ...data,
        };
    }

    @Mutation
    SET_LAND_PLOTS(data: ILandPlot[]) {
        this.landPLots = data;
        this.filteredLandPLots = data;
    }

    @Mutation
    SET_FILTERED_LAND_PLOTS(data: ILandPlot[]) {
        this.filteredLandPLots = data;
    }

    @Mutation
    SET_FLOORS(floors: number[]) {
        this.floors = [...floors];
    }

    @Mutation
    SET_FACALITIES(data: IFacility[]) {
        this.facalities = data;
    }

    @Action
    async LOAD_LAND_PLOTS() {
        try {
            const response = await fetch(getRoute('main', 'plots'));
            if (!response.ok) return console.error(response);

            const result = (await response.json()).result;
            const handledData = await landPlotDataHandler(result);
            if (!result) return console.error(response);
            this.SET_LAND_PLOTS(handledData.main);

            this.SET_GAPS_DATA_FILTER({
                price: handledData.price,
                plotArea: handledData.plotArea,
                houseArea: handledData.houseArea,
            });

            this.SET_GAPS({
                price: handledData.price,
                plotArea: handledData.plotArea,
                houseArea: handledData.houseArea,
            });
            this.SET_FLOORS(handledData.floors);
            this.SET_FACALITIES(handledData.facalities);
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async APPLY_LAND_PLOT_FILTERS() {
        this.SET_FILTERED_LAND_PLOTS(
            await landPlotDataFilter(this.landPLots, this.landPLotsFilters),
        );
    }
}

export const landPlotStore = getModule(LandPLot);
