export enum mapZones {
    southeast = 'southeast',
    empty = '',
}

export enum mapZonesNames {
    southeast = 'Зона домов',
    empty = '',
}

export interface IGlobalMapZoneHoverInfo {
    id: string;
    imgUrl: string;
    title: string;
    plotscount: number;
    freePlotsCount: number;
    areHousesExist: boolean;
}
