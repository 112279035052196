
























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { throttle } from 'lodash';

import HorizontalMapScroll from '@/components/layouts/HorizontalMapScroll.vue';

import Map from '@/components/maps/Map.vue';
import GeneralMapHoverPopup from '@/components/maps/generalMap/popups/GeneralMapHoverPopup.vue';
import GeneralMapHeader from '@/components/maps/generalMap/GeneralMapHeader.vue';
import YandexMap from '@/components/maps/YandexMap.vue';

import LandPlotPopover from '@/components/popovers/LandPlotPopover.vue';
import LandPLotModal from '@/components/modals/LandPlot/index.vue';
import PlotListForMobile from '@/components/maps/PlotListForMobile.vue';
import PlotFilters from '@/components/maps/PlotFilters.vue';

import { domRectPlaceholder } from '@/placeholders/domRect';
import { landPlotPlaceholder } from '@/placeholders/landPlot';

enum StatusTheme {
    free = '#20A61E',
    booked = '#DDC15D',
    occupied = '#D8372D',
    blocked = '#cbcbcb',
}

import { ILandPlot, PlotStatus } from '@/types/LandPLot';
@Component({
    components: {
        HorizontalMapScroll,
        Map,
        GeneralMapHoverPopup,
        GeneralMapHeader,
        YandexMap,
        LandPlotPopover,
        LandPLotModal,
        PlotListForMobile,
        PlotFilters,
    },
})
export default class GeneralMap extends Vue {
    @Prop({ default: () => [] }) landPlotsList!: ILandPlot[];
    throttle = throttle;
    target: null | HTMLElement = null;
    isMasterPlan = true;
    isActiveFilter = false;

    isPlotListVisible = false;
    isPlotFiltersVisible = false;

    // Участок, на ноду котрой нажали
    cLickedPlotNumber: number | null = null;

    // Пременные для наведения на участок
    currentHoveredPlotNumber: number | null = null;
    currentHoveredPlotDomRect: DOMRect = domRectPlaceholder;
    popoverHideTimeout = 0;

    // HTML ноды всех участков
    landPlotsListHTMLNodes: NodeListOf<SVGPathElement> | null = null;

    //  Хэш таблица со всеми участками активной зоны
    get currentZonePlots(): { [key: string]: ILandPlot } {
        return this.landPlotsList.reduce(
            (acc: { [key: string]: ILandPlot }, plot: ILandPlot) => {
                acc[+plot.plot.number] = plot;

                return acc;
            },
            {},
        );
    }

    // Участок, на ноду кторой наведена мышь
    get currentHoveredPlot(): ILandPlot {
        return (
            this.currentZonePlots[this.currentHoveredPlotNumber || -1] ||
            landPlotPlaceholder
        );
    }

    get cLickedPlot() {
        return (
            this.currentZonePlots[this.cLickedPlotNumber || -1] ||
            landPlotPlaceholder
        );
    }

    // устанавливаем статус фильтра активен/не активен
    setStatusFilter(status: boolean) {
        this.isActiveFilter = status;
    }

    // переключаем карту мастерплан/яндекс карта
    onSwitchPlan(v: boolean) {
        this.isMasterPlan = v;
        this.$emit('onSwitchPlan', v);
    }

    // Красим участки по статусу
    setPlotColours() {
        if (!this.landPlotsListHTMLNodes) return null;
        this.landPlotsListHTMLNodes.forEach((node: SVGPathElement) => {
            const id: string = node.id.slice(3);
            if (!id || !+id) return;

            const status: PlotStatus = this.currentZonePlots[+id]
                ? this.currentZonePlots[+id].plot.status
                : PlotStatus.blocked;

            node.style.stroke = StatusTheme[status];
            node.style.fill = StatusTheme[status];

            node.style.strokeWidth = '3px';
            node.style.strokeOpacity = '1';
            node.style.fillOpacity = '0.2';

            if (status === PlotStatus.blocked) {
                node.style.pointerEvents = 'none';
                node.style.touchAction = 'none';
            } else {
                node.style.pointerEvents = 'unset';
                node.style.touchAction = 'unset';
            }

            return;
        });
    }

    onMouseOver(e: MouseEvent) {
        if (e.target instanceof Element) {
            clearTimeout(this.popoverHideTimeout);
            const target = e.target;
            const pathId = target.id ? target.id : '';
            const id = pathId.replace('id_', '');
            this.currentHoveredPlotNumber = +id || null;
            if (e.target.id)
                this.currentHoveredPlotDomRect =
                    target.getBoundingClientRect();
        }
    }

    onMouseOut(e: MouseEvent) {
        this.popoverHideTimeout = setTimeout(
            () => (this.currentHoveredPlotNumber = null),
            150,
        );
    }

    async onClick(e: MouseEvent) {
        if (!(e.target instanceof Element)) return;
        const target = e.target;
        const id = target.id ? target.id.slice(3) : '';
        if (!id || !+id) return;
        this.currentHoveredPlotNumber = null;
        this.cLickedPlotNumber = +id;
    }

    onSetPlotNumber(plotNumber: number) {
        this.cLickedPlotNumber = plotNumber || null;
    }

    mounted() {
        setTimeout(() => {
            this.landPlotsListHTMLNodes =
                this.$el.querySelectorAll('g.event path');
            this.setPlotColours();
        });
    }

    @Watch('landPlotsList')
    on() {
        this.setPlotColours();
    }
}
