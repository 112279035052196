





















import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomButton from '@/components/commons/CustomControls/CustomButton.vue';
import CustomList from '@/components/commons/CustomList.vue';
import { CustomListItem } from '@/types/common/CustomList';
@Component({
    components: {
        CustomButton,
        CustomList,
    },
})
export default class WindowHouse extends Vue {
    @Prop({ default: 'odd' }) theme!: string; //odd, even
    @Prop({ default: () => [] }) list!: Array<CustomListItem>;
}
