import { IserviceList } from '@/types/Service';

export const domain = 'localhost';

const services: IserviceList = {
    main: {
        domain: `${process.env.VUE_APP_DOMAIN}`,
        secure: process.env.VUE_APP_SECURE === 'true',
        routes: {
            plots: 'getPlotList',
            facilities: 'facilities',
        },
    },
};

export default services;
