import Vue from 'vue';
import Vuex from 'vuex';

import { IFacilityStore } from '@/store/modules/Facility';
import { ILandPLotStore } from '@/store/modules/LandPLot';
import { IMapScrollStore } from '@/store/modules/MapScroll';

Vue.use(Vuex);

export interface IRootState {
    facilityStore: IFacilityStore;
    landPLotStore: ILandPLotStore;
    mapScrollStore: IMapScrollStore;
}

export default new Vuex.Store<IRootState>({});
