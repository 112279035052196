

































import { Component, Vue, Prop } from 'vue-property-decorator';
import FilterIcon from '@/components/commons/icons/FilterIcon.vue';
import { mapScrollStore } from '@/store/modules/MapScroll';
import { mapZones } from '@/types/common/MapZones';

@Component({
    components: { FilterIcon },
})
export default class MapFooter extends Vue {
    @Prop() currentZone!: mapZones;

    statusList: Array<{
        id: number;
        text: string;
        status: string;
        color: string;
    }> = [
        {
            id: 0,
            text: 'Свободен',
            status: 'success',
            color: '#4D822C',
        },
        {
            id: 1,
            text: 'Забронирован',
            status: 'warning',
            color: '#E9C442',
        },
        {
            id: 2,
            text: 'Занят',
            status: 'danger',
            color: '#C04B20',
        },
    ];

    get isLegendVisible() {
        return !!this.currentZone;
    }

    get translateX() {
        return mapScrollStore.TRANSLATE_X;
    }

    scrollToLeft() {
        mapScrollStore.SET_TRANSLATE_X(100);
    }

    scrollToRight() {
        mapScrollStore.SET_TRANSLATE_X(-100);
    }
}
