










import { Component, Vue } from 'vue-property-decorator';
import ListIcon from '@/components/commons/icons/ListIcon.vue';
@Component({
    components: {
        ListIcon,
    },
})
export default class CustomListButton extends Vue {}
