
















import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
    components: {},
})
export default class CustomButton extends Vue {
    @Prop({ default: 'md' }) size!: string;
    @Prop({ default: 'danger' }) theme!: string;
}
