














































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    components: {},
})
export default class BackIcon extends Vue {
    @Prop({ default: '#000' }) color!: string;
}
