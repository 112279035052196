






































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ModalTemplate from '@/components/layouts/ModalTemplate.vue';

import LandPlotModalTop from './LandPlotModalTop.vue';
import LandPlotModalBottom from './LandPlotModalBottom.vue';
import LandPlotGalleryFullScreen from '@/components/modals/LandPlot/LandPlotGalleryFullScreen.vue';

import { landPlotPlaceholder } from '@/placeholders/landPlot';
import { ILandPlot } from '@/types/LandPLot';

@Component({
    components: {
        ModalTemplate,
        LandPlotModalTop,
        LandPlotModalBottom,
        LandPlotGalleryFullScreen,
    },
})
export default class LandPlotModal extends Vue {
    @Prop({ default: () => landPlotPlaceholder }) landPlot!: ILandPlot;
    indexActiveSlider = 0;

    isPlotGalleryFullScreen = false;
    isHouseGalleryFullScreen = false;

    get houseImgaes(): string[] {
        return this.landPlot && this.landPlot.house
            ? this.landPlot.house.images
            : [];
    }

    openInFullScreenPlotModalGallery(data: number) {
        this.indexActiveSlider = data;
        this.isPlotGalleryFullScreen = true;
    }

    openInFullScreenHouseModalGallery(data: number) {
        this.indexActiveSlider = data;
        this.isHouseGalleryFullScreen = true;
    }

    @Watch('isPlotGalleryFullScreen')
    onScroll() {
        if (this.isPlotGalleryFullScreen || this.isHouseGalleryFullScreen)
            document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
    }
}
