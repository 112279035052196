







































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ModalTemplate from '@/components/layouts/ModalTemplate.vue';
import CustomButton from '@/components/commons/CustomControls/CustomButton.vue';
import { cashSpacer } from '@/helpers/cashHelpers';
import { ILandPlot } from '@/types/LandPLot';

@Component({
    components: {
        ModalTemplate,
        CustomButton,
    },
})
export default class PlotListForMobile extends Vue {
    @Prop() landPlotList!: ILandPlot[];
    @Prop() isVisible!: boolean;
    @Prop() isActiveFilter!: boolean;

    price(data: number) {
        return cashSpacer(data, ' ');
    }

    async onClickToPlot(plotNumber: string | number) {
        this.$emit('clickToPlot', Number(plotNumber));
        this.$emit('close');
    }
}
