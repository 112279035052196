












import { Component, Vue, Prop } from 'vue-property-decorator';

import GeneralMap from '@/components/maps/generalMap/GeneralMap.vue';
import MapFooter from '@/components/maps/MapFooter.vue';

import { ILandPlot } from '@/types/LandPLot';

@Component({
    components: {
        GeneralMap,
        MapFooter,
    },
})
export default class MapsSwitcher extends Vue {
    @Prop({ default: () => [] }) landPlotsList!: ILandPlot[];

    isMasterPlan: boolean = true;
}
