// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/CardHouse/treangle.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".card-template{position:fixed;width:312px;height:auto;background-color:#fff;border-radius:0;transform:translate(-50%,-100%);transition:all .3s ease;z-index:10000;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;pointer-events:none;touch-action:none}.card-template,.card-template *{box-sizing:border-box}@media (max-width:948px){.card-template{display:none!important}}.card-template__wrapper{padding:12px}.card-template__triangle{position:absolute;bottom:-5px;left:50%;transform:translateX(-50%);width:28px;height:8px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:top;background-size:100%;background-repeat:no-repeat;transition:all .3s ease;z-index:100}", ""]);
// Exports
module.exports = exports;
