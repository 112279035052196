

























import { Component, Vue } from 'vue-property-decorator';
import { mapScrollStore } from '@/store/modules/MapScroll';

@Component({
    components: {},
})
export default class HorizontalMapScroll extends Vue {
    get translateX() {
        return mapScrollStore.TRANSLATE_X === 0
            ? mapScrollStore.TRANSLATE_CENTER
            : mapScrollStore.TRANSLATE_X;
    }

    createScrollDataObject() {
        let wrapper = this.$refs.wrapper;
        let wrapperInner = this.$refs.inner;

        if (
            !(wrapper instanceof HTMLDivElement) ||
            !(wrapperInner instanceof HTMLDivElement)
        ) {
            console.error('map horizontal wrapper error');
            mapScrollStore.SET_DATA({
                mapWrapper: null,
                mapWrapperItem: null,
                z: 0,
                vw: 0,
                Xmax: 0,
            });
        } else
            mapScrollStore.SET_DATA({
                mapWrapper: wrapper,
                mapWrapperItem: wrapperInner,
                z: wrapper.getBoundingClientRect().width,
                vw: wrapperInner.getBoundingClientRect().width,
                Xmax:
                    wrapper.getBoundingClientRect().width -
                    wrapperInner.getBoundingClientRect().width,
            });
    }

    mounted() {
        setTimeout(() => {
            mapScrollStore.RELOAD_TRANSLATE_X();
            this.createScrollDataObject();
            window.addEventListener('resize', this.createScrollDataObject);
            window.addEventListener(
                'resize',
                mapScrollStore.RELOAD_TRANSLATE_X,
            );
        }, 100);
    }

    beforeDestroy() {
        mapScrollStore.RELOAD_TRANSLATE_X();
        window.removeEventListener('resize', this.createScrollDataObject);
        window.removeEventListener(
            'resize',
            mapScrollStore.RELOAD_TRANSLATE_X,
        );
    }
}
