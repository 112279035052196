






































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ModalTemplate from '@/components/layouts/ModalTemplate.vue';
import CustomButton from '@/components/commons/CustomControls/CustomButton.vue';
import CustomCheckbox from '@/components/commons/CustomInputs/CustomCheckbox.vue';
import CustomRangeSelect from '@/components/commons/CustomInputs/CustomRangeSelect.vue';

import { landPlotStore } from '@/store/modules/LandPLot';
import { PlotStatus } from '@/types/LandPLot';

@Component({
    components: {
        ModalTemplate,
        CustomButton,
        CustomCheckbox,
        CustomRangeSelect,
    },
})
export default class PlotFilters extends Vue {
    @Prop() isVisible!: boolean;

    // максимумы и минимумы
    get minPlotArea() {
        return landPlotStore.PLOT_AREA_GAP.min;
    }
    get maxPlotArea() {
        return landPlotStore.PLOT_AREA_GAP.max;
    }

    get minPricePlot() {
        return landPlotStore.PRICE_GAP.min;
    }
    get maxPricePlot() {
        return landPlotStore.PRICE_GAP.max;
    }

    get minHouseArea() {
        return landPlotStore.HOUSE_AREA_GAP.min;
    }
    get maxHouseArea() {
        return landPlotStore.HOUSE_AREA_GAP.max;
    }

    get facilitiesStatus() {
        return this.facilities.map(item => {
            const status = Object.values(
                landPlotStore.LAND_PLOT_FILTERS.facilities,
            ).includes(item.id)
                ? true
                : false;
            return {
                ...item,
                status: status,
            };
        });
    }

    // максимумы и минимумы
    get floors() {
        return landPlotStore.FLOORS;
    }

    get facilities() {
        return landPlotStore.FACALITIES;
    }

    get priceLandPlotGap() {
        return {
            min: landPlotStore.LAND_PLOT_FILTERS.landPLotPriceMin,
            max: landPlotStore.LAND_PLOT_FILTERS.landPLotPriceMax,
        };
    }
    set priceLandPlotGap(data: { min: number; max: number }) {
        landPlotStore.SET_LAND_PLOT_FILTERS({
            landPLotPriceMin: data.min,
            landPLotPriceMax: data.max,
        });
    }
    //
    get areaLandPlotGap() {
        return {
            min: landPlotStore.LAND_PLOT_FILTERS.landPLotAreaMin,
            max: landPlotStore.LAND_PLOT_FILTERS.landPLotAreaMax,
        };
    }
    set areaLandPlotGap(data: { min: number; max: number }) {
        landPlotStore.SET_LAND_PLOT_FILTERS({
            landPLotAreaMax: data.max,
            landPLotAreaMin: data.min,
        });
    }
    //
    get houseAreaGap() {
        return {
            min: landPlotStore.LAND_PLOT_FILTERS.houseAreaMin,
            max: landPlotStore.LAND_PLOT_FILTERS.houseAreaMax,
        };
    }
    set houseAreaGap(data: { min: number; max: number }) {
        landPlotStore.SET_LAND_PLOT_FILTERS({
            houseAreaMin: data.min,
            houseAreaMax: data.max,
        });
    }

    //

    // свободные
    get free() {
        return !!(
            landPlotStore.LAND_PLOT_FILTERS.status.indexOf(
                PlotStatus.free,
            ) + 1
        );
    }
    set free(data: boolean) {
        const result: PlotStatus[] = this.free
            ? landPlotStore.LAND_PLOT_FILTERS.status.filter(
                  elem => elem != PlotStatus.free,
              )
            : [...landPlotStore.LAND_PLOT_FILTERS.status, PlotStatus.free];

        landPlotStore.SET_LAND_PLOT_FILTERS({ status: result });
    }
    // Забронированные
    get booked() {
        return !!(
            landPlotStore.LAND_PLOT_FILTERS.status.indexOf(
                PlotStatus.booked,
            ) + 1
        );
    }
    set booked(data: boolean) {
        const result: PlotStatus[] = this.booked
            ? landPlotStore.LAND_PLOT_FILTERS.status.filter(
                  elem => elem != PlotStatus.booked,
              )
            : [
                  ...landPlotStore.LAND_PLOT_FILTERS.status,
                  PlotStatus.booked,
              ];

        landPlotStore.SET_LAND_PLOT_FILTERS({ status: result });
    }

    get allowedFloors() {
        return landPlotStore.LAND_PLOT_FILTERS.allowedFloors;
    }

    get allowedFacilities() {
        return landPlotStore.LAND_PLOT_FILTERS.facilities;
    }

    async applyFilter() {
        await landPlotStore.APPLY_LAND_PLOT_FILTERS();
        this.$emit('close');
        this.$emit('setStatusFilter', true);
    }

    async applyFilterAndShowList() {
        await landPlotStore.APPLY_LAND_PLOT_FILTERS();

        this.$emit('close');
        this.$emit('openFilter');
        this.$emit('setStatusFilter', true);
    }

    resetFilter() {
        landPlotStore.RESET_LAND_PLOT_FILTERS();
        this.$emit('close');
        this.$emit('setStatusFilter', false);
    }
    resetFilterAndShowList() {
        landPlotStore.RESET_LAND_PLOT_FILTERS();
        this.$emit('close');
        this.$emit('openFilter');
        this.$emit('setStatusFilter', false);
    }

    onfilterFloorsChanged(e: InputEvent, floor: number) {
        const res =
            landPlotStore.LAND_PLOT_FILTERS.allowedFloors.indexOf(floor) + 1
                ? landPlotStore.LAND_PLOT_FILTERS.allowedFloors.filter(
                      item => item !== floor,
                  )
                : [...landPlotStore.LAND_PLOT_FILTERS.allowedFloors, floor];

        landPlotStore.SET_LAND_PLOT_FILTERS({
            allowedFloors: res,
        });
    }

    onfilterFacilityChanged(e: InputEvent, facilitiesItem: number) {
        let isInStore =
            !!landPlotStore.LAND_PLOT_FILTERS.facilities[facilitiesItem];
        const res: { [key: string]: number } = {
            ...landPlotStore.LAND_PLOT_FILTERS.facilities,
        };
        if (res[facilitiesItem]) delete res[facilitiesItem];
        else res[facilitiesItem] = facilitiesItem;

        if (isInStore) delete res[facilitiesItem];
        landPlotStore.SET_LAND_PLOT_FILTERS({
            facilities: res,
        });
    }
}
