// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/CardHouse/treangle.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/CardHouse/flag-discount.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".land-plot-popover{opacity:0;touch-action:none;pointer-events:none}.land-plot-popover--hide{opacity:0!important}.land-plot-popover__triangle{position:absolute;bottom:-5px;left:50%;transform:translateX(-50%);width:28px;height:8px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:top;background-size:100%;background-repeat:no-repeat;z-index:100}.land-plot-popover__header{background-position:50%;background-size:cover;background-repeat:no-repeat;border-radius:0;width:100%;height:160px;padding:14px;margin-bottom:16px;background-color:rgba(28,126,75,.5)}.land-plot-popover__header-status{padding:5px 8px;height:26px;display:inline-flex;align-items:center;justify-content:center;text-align:center;background-color:#fff;border-radius:0}.land-plot-popover__body-list,.land-plot-popover__body-title{margin-bottom:14px}.land-plot-popover__body-list-item{display:flex;justify-content:space-between;align-items:center;padding:13px 16px;border-radius:0;background-color:#f6f6f6;margin-bottom:3px}.land-plot-popover__body-list-item:last-child{margin-bottom:0}.land-plot-popover__body-price{display:grid;grid-template-columns:1fr auto;align-items:center;padding:0 16px 14px 16px}.land-plot-popover__void{padding:12px}.card-plot-discount{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:100%;background-repeat:no-repeat;background-position:top;width:54px;height:70px;position:absolute;top:0;right:36px;text-align:center}.card-plot-discount__action{text-align:center;margin-top:20px}.card-plot-discount__action,.card-plot-discount__text{font-weight:400;font-size:11px;line-height:150%;color:#fff}.card-plot-discount__text{margin-top:15px;margin-bottom:-3px}.card-plot-discount__value{font-weight:600;font-size:16px;line-height:150%;color:#fff}", ""]);
// Exports
module.exports = exports;
